import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import get from 'lodash/get'

class PageNotFound extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')    

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={siteTitle} />
          <div className="page-not-found">404 Page not Found</div>          
        </div>
      </Layout>
    )
  }
}

export default PageNotFound